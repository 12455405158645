import React from "react"

function thanks() {
  return (
    <div>
      <p>Thank you for your time!</p>
    </div>
  )
}

export default thanks
